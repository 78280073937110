<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import LoadBar from './loadBar.svelte';
  import { onMount } from 'svelte';

  export let header: string;
  export let message: string;

  export let primaryButton: string = 'OK';
  export let secondaryButton: string = '';

  export let color: string = '#FFFFFF';
  export let loading: boolean = false;
  export let animations: boolean = true;

  let showElements: boolean = false;
  let visible: boolean = false;

  let additionalClassStr: string = '';

  const dispatch = createEventDispatcher();

  function buttonClicked(buttonId: number) {
    finish(buttonId);
  }

  async function finish(clickedButtonId: number) {
    additionalClassStr = '';
    await wait(1000);
    visible = false;
    dispatch('closed', {
      button: clickedButtonId,
    });
  }

  async function wait(ms: number) {
    return new Promise<void>((resolve, reject) => {
      if (animations) {
        setTimeout(() => {
          resolve();
        }, ms);
      } else {
        resolve();
      }
    });
  }
  async function show() {
    visible = true;
    await wait(100);
    if (!animations) {
      additionalClassStr = 'active skipAnim';
    } else {
      additionalClassStr = 'active';
    }
    await wait(1000);
    showElements = true;
  }
  onMount(() => {
    show();
  });
</script>

{#if visible}
  <div id="wrapper">
    <div class="flex flex-col justify-around items-center">
      <div
        id="container"
        class="border-2 rounded-md flex flex-col justify-around items-center text-center {additionalClassStr}"
        style="border-color:{color}"
      >
        {#if showElements}
          {#if loading}
            <LoadBar loaderColor={color} />
          {:else}
            <p
              id="header"
              class="text-2xl text-red-300 mt-8 text-center {additionalClassStr}"
              style="color:{color}"
            >
              {header}
            </p>
            <p
              id="message"
              class="text-lg text-red-300 mt-4 text-center {additionalClassStr}"
              style="color:{color}"
            >
              {message}
            </p>
            <div>
              <button
                id="ok"
                class="border-2 rounded-md marker:text-xl mt-16 text-center {additionalClassStr}"
                style="border-color:{color};color:{color}"
                on:click={() => {
                  buttonClicked(1);
                }}
              >
                {primaryButton}
              </button>
              {#if secondaryButton}
                <button
                  id="ok"
                  class="border-2 rounded-md marker:text-xl mt-16 text-center {additionalClassStr}"
                  style="border-color:{color};color:{color}"
                  on:click={() => {
                    buttonClicked(2);
                  }}
                >
                  {secondaryButton}
                </button>
              {/if}
            </div>
          {/if}
        {/if}
      </div>
    </div>
  </div>
{/if}

<style>
  * {
    transition-duration: 1s;
    opacity: 0;
  }
  div {
    opacity: 1;
  }
  button {
    width: 6rem;
  }
  #wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
  #container {
    width: 0px;
    height: 0px;
  }
  #container.active {
    width: 18rem;
    height: 16rem;
    animation: appear forwards;
    animation-duration: 100ms;
    background-color: var(--bg-color);
  }

  #header.active:not(.skipAnim) {
    animation: appear forwards;
    animation-delay: 100ms;
    animation-duration: 1s;
  }
  #message.active:not(.skipAnim) {
    animation: appear forwards;
    animation-delay: 200ms;
    animation-duration: 1s;
  }
  #ok.active:not(.skipAnim) {
    animation: appear forwards;
    animation-delay: 300ms;
    animation-duration: 1s;
  }
  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .skipAnim {
    opacity: 1;
    animation: none;
  }
</style>
